<template>
  <div class="test">
    <header class="header">
      <div class="now">
        <div class="date">{{ +new Date() | dateNow }}</div>
        <div class="time">{{ nowTime }}</div>

        <div class="weather">
          <img
            class="img"
            :src="$imgOnlineUrl + '/index/weather/' + weatherObj.icon + '.png'"
          />
          <span>{{ weatherObj.text }} {{ weatherObj.temp }}℃</span>
        </div>
      </div>
      <div class="logo">
        <!-- <img class="logo-img" :src="$imgOnlineUrl + '/bigWeb/head/DK-1.png'" /> -->
        <span class="logo-txt">含山县大米智慧农业平台</span>
      </div>

      <div class="title">
        <img
          class="other-txt"
          :src="$imgOnlineUrl + '/bigWeb/head/icon.png'"
          alt="后台界面"
          title="后台界面"
          @click="goReturn"
        />
        大旭数字农业平台
      </div>
    </header>
    <div class="main animated fadeInUp">
      <div class="main-left">
        <!-- 水稻种植 -->
        <div class="equipment">
          <p class="title">水稻种植</p>
          <div class="rice-main">
            <div class="rice-all">
              <img
                class="icon"
                src="@/assets/image/bigWeb/equipment/icon.png"
                alt=""
              />
              <p class="number">
                <span>47843.9</span>
                <span>亩</span>
              </p>
              <p class="unit">总面积</p>
            </div>
            <div class="rice-item">
              <p class="number">
                <span>23605.2</span>
                <span>亩</span>
              </p>
              <p class="unit">华浙优261</p>
            </div>
            <div class="rice-item">
              <p class="number">
                <span>7608.7</span>
                <span>亩</span>
              </p>
              <p class="unit">南粳46</p>
            </div>
            <div class="rice-item">
              <p class="number">
                <span>8272</span>
                <span>亩</span>
              </p>
              <p class="unit">农两优998</p>
            </div>
            <div class="rice-item">
              <p class="number">
                <span>8358</span>
                <span>亩</span>
              </p>
              <p class="unit">珠两优5298</p>
            </div>
          </div>
        </div>
        <div class="personnel">
          <p class="title">种植情况</p>
          <div class="chart-personnel">
            <v-chart
              class="v-chart"
              autoresize
              :option="line_personnel"
            ></v-chart>
          </div>
        </div>
        <div class="traceability">
          <p class="title">种植分布</p>
          <div class="traceability-lists">
            <v-chart
              class="distribute-chart"
              autoresize
              :option="distributeCharts"
            ></v-chart>
          </div>
        </div>
      </div>
      <div class="main-center">
        <div class="other map" id="map">
          <div class="nook nook-top-left"></div>
          <div class="nook nook-bottom-left"></div>
          <div class="nook nook-top-right"></div>
          <div class="nook nook-bottom-right"></div>
        </div>
        <!-- 实时监测数据 -->
        <div class="real-time-data">
          <p class="title now-data">实时监测数据</p>
          <!-- data-content 早晚删 -->
          <div data-v-58d597ac="" class="data-content">
            <div data-v-58d597ac="" class="content-items weather-cont">
              <p data-v-58d597ac="" class="contents-title">实时环境监测</p>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/co2.png"
                  /><span data-v-58d597ac="">二氧化碳浓度</span>
                </div>
                <div data-v-58d597ac="" class="r">86.4ppm</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/airH.png"
                  /><span data-v-58d597ac="">空气湿度</span>
                </div>
                <div data-v-58d597ac="" class="r">29.7%</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/airT.png"
                  /><span data-v-58d597ac="">空气温度</span>
                </div>
                <div data-v-58d597ac="" class="r">5394℃</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/ill.png"
                  /><span data-v-58d597ac="">光照度</span>
                </div>
                <div data-v-58d597ac="" class="r">413Lux</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/PAR.png"
                  /><span data-v-58d597ac="">光合有效辐射</span>
                </div>
                <div data-v-58d597ac="" class="r">0w/㎡</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/rainF.png"
                  /><span data-v-58d597ac="">降雨量</span>
                </div>
                <div data-v-58d597ac="" class="r">1.2mm</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/TBQ.png"
                  /><span data-v-58d597ac="">TBQ总辐射</span>
                </div>
                <div data-v-58d597ac="" class="r">28w/㎡</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/windS.png"
                  /><span data-v-58d597ac="">风速</span>
                </div>
                <div data-v-58d597ac="" class="r">2m/s</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/sDur.png"
                  /><span data-v-58d597ac="">日照时数</span>
                </div>
                <div data-v-58d597ac="" class="r">35分钟</div>
              </div>
            </div>
            <div data-v-58d597ac="" class="content-items weather-cont">
              <p data-v-58d597ac="" class="contents-title">实时土壤监测</p>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/soilH.png"
                  /><span data-v-58d597ac="">土壤湿度</span>
                </div>
                <div data-v-58d597ac="" class="r">100%</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/soilT.png"
                  /><span data-v-58d597ac="">土壤温度</span>
                </div>
                <div data-v-58d597ac="" class="r">100℃</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/soilC.png"
                  /><span data-v-58d597ac="">土壤电导率</span>
                </div>
                <div data-v-58d597ac="" class="r">28.9uS/cm</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/soilS.png"
                  /><span data-v-58d597ac="">土壤盐分</span>
                </div>
                <div data-v-58d597ac="" class="r">29.6ppm</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/dPH.png"
                  /><span data-v-58d597ac="">干式PH值</span>
                </div>
                <div data-v-58d597ac="" class="r">72</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/sN.png"
                  /><span data-v-58d597ac="">土壤氮传感器</span>
                </div>
                <div data-v-58d597ac="" class="r">36mg/kg</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/sP.png"
                  /><span data-v-58d597ac="">土壤磷传感器</span>
                </div>
                <div data-v-58d597ac="" class="r">7.9mg/kg</div>
              </div>
              <div
                data-v-58d597ac=""
                class="contents-main-items weather-cont-items"
              >
                <div data-v-58d597ac="" class="l">
                  <img
                    data-v-58d597ac=""
                    src="https://data.darsing.net/images/pc/bigWeb/realData/sK.png"
                  /><span data-v-58d597ac="">土壤钾传感器</span>
                </div>
                <div data-v-58d597ac="" class="r">31mg/kg</div>
              </div>
            </div>
          </div>
          <!-- <div class="data-content">
            <div class="content-items weather-cont">
              <p class="contents-title">实时环境监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.qixiang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div v-if="item.online == 1" class="r">
                  {{ item.last_data }}{{ item.data_unit }}
                </div>
              </div>
            </div>
            <div class="content-items weather-cont">
              <p class="contents-title">实时土壤监测</p>
              <div
                class="contents-main-items weather-cont-items"
                v-for="item in chemicalList.turang"
                :key="item.id"
              >
                <div class="l">
                  <img
                    v-if="item.online == 1"
                    :src="
                      $imgOnlineUrl + '/bigWeb/realData/' + item.icon + '.png'
                    "
                  />
                  <img
                    v-else
                    :src="
                      $imgOnlineUrl +
                      '/bigWeb/realData/' +
                      item.icon +
                      '-offline.png'
                    "
                  />
                  <span>{{ item.title }}</span>
                </div>
                <div v-if="item.online == 1" class="r">
                  {{ item.last_data }}{{ item.data_unit }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="main-right">
        <div class="stoving">
          <p class="title">烘干设备</p>
          <div class="stoving-list">
            <div
              class="stoving-item"
              v-for="(item, index) in stovingList"
              :key="index"
            >
              <img class="dry-img" :src="item.img" alt="" />
              <div class="dry-info">
                <div class="info-title">{{ item.title }}</div>
                <div class="info-cont">{{ item.num }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="monitor">
          <p class="title">视频监控</p>
          <div class="monitor-lists">
            <div
              class="monitor-items"
              v-for="item in chemicalList.jiankong"
              :key="item.id"
            >
              <iframe
                frameborder="0"
                name="video"
                scrolling="no"
                width="192"
                height="136"
                :src="`https://hs.darsing.net/project/digital2/video/address?eid=${item.id}&return_type=iframe`"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Overlay from "ol/Overlay";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import * as turf from "@turf/turf";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Collection from "ol/Collection";
import { Style, Fill, Stroke, Icon } from "ol/style";

import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import * as Interaction from "ol/interaction";
import {
  TitleComponent,
  TooltipComponent,
  DatasetComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
]);

import moment from "moment";
import hanshan from "@/assets/json/hanshan.json";

import {
  getEquipmentGroupApi,
  getEquipmentListApi,
  getEquipmentInfoApi,
  getEquipmentChildListApi,
  getWeatherApi,
  getAllMarkDataApi,
  getCls2Api,
  getCls3Api,
} from "@/request/api";
import * as R from "ramda";
import { mapState } from "vuex";
export default {
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  data() {
    return {
      stovingList: [
        {
          title: "热风温度",
          num: "35.6℃",
          img: require("@/assets/image/bigWeb/stoving/re.png"),
        },
        {
          title: "谷物温度",
          num: "17.6℃",
          img: require("@/assets/image/bigWeb/stoving/gw.png"),
        },
        {
          title: "设定水分",
          num: "35ml/g",
          img: require("@/assets/image/bigWeb/stoving/sd.png"),
        },
        {
          title: "实际水分",
          num: "36ml/g",
          img: require("@/assets/image/bigWeb/stoving/sj.png"),
        },
        {
          title: "设定温度",
          num: "35.6℃",
          img: require("@/assets/image/bigWeb/stoving/wd.png"),
        },
        {
          title: "温度上限",
          num: "35.6℃",
          img: require("@/assets/image/bigWeb/stoving/sx.png"),
        },
      ],
      hanShanSource: new VectorSource({
        features: new GeoJSON().readFeatures(hanshan, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:4326",
        }),
      }),
      allDataList: {},

      groupTitle: [],
      // 天气
      cameraList: [],
      polygonsData: [],
      vsource: new VectorSource({}),
      weatherObj: {},
      // 视频分页
      videoPageNum: 1,
      centerVal: 0,
      videoPageSize: 10,
      videoCount: 0,
      videoId: "",
      titleLayer: {},
      // 设备
      chemicalList: {
        turang: [],
        qixiang: [],
        jiankong: [],
      },
      // 定时器
      setInterval: null,
      setTime: null,
      setInterval2: null,

      // 溯源动态
      titleFeatures: [],
      traceabilityList: [],
      // 实时时间
      nowTime: "",
      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
      line_personnel: {
        backgroundColor: "transparent",
        legend: {
          right: 20,
          top: 20,
          bottom: 20,
          orient: "vertical",
          align: "right",
          itemGap: 16,
          type: "scroll",
        },
        tooltip: {},
        series: [
          {
            type: "pie",
            avoidLabelOverlap: true,
            showEmptyCircle: true,
            radius: ["30%", "40%"],
            center: ["23%", "25%"],
            label: {
              show: true,
              position: "center",
              formatter: "",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "pie",
            avoidLabelOverlap: true,
            showEmptyCircle: true,
            radius: ["30%", "40%"],
            center: ["55%", "25%"],
            label: {
              show: true,
              position: "center",
              formatter: "",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "pie",
            radius: ["30%", "40%"],
            avoidLabelOverlap: true,
            showEmptyCircle: true,
            center: ["23%", "72%"],
            label: {
              show: true,
              position: "center",
              formatter: "",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "pie",
            avoidLabelOverlap: true, //防止重叠
            showEmptyCircle: true, //占位
            radius: ["30%", "40%"],
            center: ["55%", "72%"],
            label: {
              show: true,
              position: "center",
              formatter: "",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      distributeCharts: {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "28%",
          top: "22%",
          bottom: "12%",
        },
        legend: {},
        dataset: {
          source: [],
        },
        xAxis: { type: "category" },
        yAxis: {
          // name: "面积(单位:平方米)",
          type: "value",
        },
        series: [],
      },
    };
  },
  mounted() {
    this.getAllMarkDataApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      userInfo: (state) => state.user.userInfo,
      farmInfo: (state) => state.user.farmInfo,
    }),
    defaultOption() {
      return {
        step: 0.2,
        limitMoveNum: 2,
        hoverStop: true,
        direction: 1,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
  created() {
    this.getNowTime();
    this.allFunction();
    this.updatePage();
  },
  beforeDestroy() {
    // 卸载定时器
    if (this.setInterval) {
      clearInterval(this.setInterval);
    }
    this.setInterval = null;
    if (this.setInterval2) {
      clearInterval(this.setInterval2);
    }
    this.setInterval2 = null;
    if (this.setTime) {
      clearInterval(this.setTime);
      this.setTime = null;
    }
  },

  methods: {
    async getCls3Api() {
      const { code, results } = await getCls3Api({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.distributeCharts.dataset.source[0] = ["product", ...results.crops];
      let keys = Object.keys(results.data);
      let values = Object.values(results.data);
      keys.forEach((item, index) => {
        let newArr = [];
        newArr.push(item);
        newArr = [item, ...values[index]];

        this.distributeCharts.dataset.source.push(newArr);
        this.distributeCharts.series.push({
          type: "bar",
        });
      });
    },

    setRepetition(arr) {
      let res = [];
      let json = {};
      for (let i = 0; i < arr.length; i++) {
        if (!json[arr[i]]) {
          res.push(arr[i]);
          json[arr[i]] = 1;
        } else {
          json[arr[i]]++;
        }
      }
      return json;
    },
    async getCls2Api() {
      const { code, results } = await getCls2Api({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      let keys = Object.keys(results.data);
      let values = Object.values(results.data);

      keys.forEach((item, index) => {
        this.line_personnel.series[index].label.formatter = item;
        let newArr = this.setRepetition(values[index]);
        let newArrKeys = Object.keys(newArr);
        let newArrValues = Object.values(newArr);
        newArrKeys.forEach((t, i) => {
          let obj = {
            name: t,
            value: newArrValues[i],
          };
          this.line_personnel.series[index].data.push(obj);
        });
      });
    },

    async getAllMarkDataApi() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;
      this.weatherCount = this.cameraList.findIndex(
        (item) => item.group === "气象采集"
      );
      this.cameraList.forEach((item, index) => {
        if (item.group === "虫情监测") {
          item.count >= 3 ? (item.count = item.count / 3) : "";
        }
        // 默认全选中
        this.groupTitle.push(item.group);
        if (item.type != "plot") {
          this.allDataList[item.group] = [];
          item.items.forEach((item2) => {
            let obj = {
              position: "",
              title: "",
              img: "",
              online: 1,
            };
            let iconType = "";
            if (item2.icon === "video") {
              iconType = "video";
            } else if (item2.icon === "weather") {
              iconType = "weather";
            } else {
              iconType = "water";
            }
            obj.title = item.group;
            obj.id = item2.id;
            obj.iconType = iconType;
            obj.icon = item2.icon;
            obj.position = [
              +item2.mark_data.split(",")[0],
              +item2.mark_data.split(",")[1],
            ];
            obj.img = `${this.$imgOnlineUrl}/index/${iconType}${item2.online}.png`;
            this.allDataList[item.group].push(obj);
            this.$forceUpdate();
            // 图标默认全显示
            this.cityPoint(this.allDataList[item.group]);
          });
          this.$forceUpdate();
        } else {
          this.polygonsData[index] = [];
          // this.titleFeatures[index] = [];
          item.items.forEach((item2) => {
            // 处理坐标
            if (item2.mark_type == 3 && item2.mark_data) {
              let polygonsItemObj = { coord: [] };
              if (typeof item2.mark_data === "string") {
                item2.mark_data = JSON.parse(item2.mark_data);
              }
              item2.mark_data.forEach((item3) => {
                polygonsItemObj.coord.push([item3[0], item3[1]]);
              });
              polygonsItemObj.color = [item2.body_color, item2.border_color];
              polygonsItemObj.name = `polygon${item2.id}`;
              polygonsItemObj.id = item2.id;
              polygonsItemObj.title = item.group;
              let polygon = turf.polygon([item2.mark_data]);
              this.centerVal = turf.centroid(polygon).geometry.coordinates;
              this.polygonsData[index].push(polygonsItemObj);
              // 标题
              let titleMarkerObj = {
                text: item2.title,
                geometry: new Point(this.centerVal),
              };
              this.titleFeatures.push(titleMarkerObj);
            }
          });
          this.$forceUpdate();
        }
      });

      this.addTitles();
      this.showPolygon();
      this.setTime = setTimeout(() => {
        this.initMap();
      }, 80);
    },
    allFunction() {
      this.loadFullDataSets();
      this.getWeatherApi();
      this.getCls2Api();
      this.getCls3Api();
    },
    // //10分钟更新一次
    updatePage() {
      this.setInterval2 = setInterval(() => {
        this.allFunction();
      }, 600000);
    },
    cityPoint(list) {
      let features = [];
      let data = list;
      for (var i = 0; i < data.length; i++) {
        let feature = new Feature({
          geometry: new Point(data[i].position),
          type: "isPoint",
          citydata: data[i],
          title: data[i].title,
        });
        feature.setStyle(this.pointStyle(data[i].online, data[i].iconType));
        features.push(feature);
      }
      this.vsource.addFeatures(features);
    },

    pointStyle(online, iconType) {
      let imgUrl = iconType + online + ".png";
      let src = this.$imgOnlineUrl + "/index/" + imgUrl;
      let Styles = [];
      Styles.push(
        new Style({
          // 设置图标
          image: new Icon({
            src,
            anchor: [0.5, 0.5],
            scale: 1,
          }),
        })
      );
      return Styles;
    },
    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");
      this.iconMarkLayer = new VectorLayer({
        zIndex: 3,
        source: this.vsource,
      });
      window.olMap = new Map({
        // 创建地图
        target: "map",
        layers: [
          new VectorLayer({
            zIndex: 3,
            maxZoom: 11,
            source: this.hanShanSource,
          }),
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer, //多边形
          this.titleLayer, //标题
          this.iconMarkLayer, //点
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: 10,
          maxZoom: 18,
          constrainResolution: true, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      });
      this.infoBox = document.getElementById("info-card");
      this.overLayer = new Overlay({
        element: this.infoBox,
        positioning: "center-center",
        offset: [0, -15],
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 4,
          color: y,
        }),
      });
      return style;
    },
    showPolygon(index = "wu") {
      // this.clearDraw();
      let hanshanpolygonsData = [];
      if (index == "wu") {
        hanshanpolygonsData = this.polygonsData;
      } else {
        hanshanpolygonsData = [this.polygonsData[index]];
      }
      hanshanpolygonsData.forEach((item) => {
        let array = item;
        let features = [];
        for (let i = 0; i < array.length; i++) {
          let coord = array[i].coord;
          let x = array[i].color[0];
          let y = array[i].color[1];
          let z = array[i].name;
          let f = new Feature({
            geometry: new Polygon([coord]),
            type: "isPolygon",
            id: array[i].id,
            title: array[i].title,
          });
          f.setStyle(this.featureStyle(x, y));
          f.setProperties({
            name: z,
          });
          features.push(f);
        }

        this.myPolygonSource.addFeatures(features);

        this.polygonLayer = new VectorLayer({
          zIndex: 3,
          minZoom: 8,
          source: this.myPolygonSource,
          style: new Style({
            fill: new Fill({
              color: "",
            }),
            stroke: new Stroke({
              width: 4,
              color: "",
            }),
          }),
        });
      });
    },

    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "rgba(22,133,144,.3)",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 13,
        source: new VectorSource({
          features: new Collection(
            // hanshanTitleData.forEach((item) => {
            // let arr = item;
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
            // })
          ),
          format: new GeoJSON(),
        }),
      });
    },
    goReturn() {
      this.$router.go(-1);
    },

    async getWeatherApi() {
      const { code, data } = await getWeatherApi({
        location: this.farmInfo.position,
      });
      if (code === 200) {
        this.weatherObj = data.now;
      }
    },
    // 获取时间
    getDateList(count, long) {
      let time = new Date().setMinutes(0);
      time = time = 24 * 60 * 60 * 1000;
      let categoryData = [];
      for (let i = 0; i <= count; i++) {
        categoryData.push(moment(time).format("HH:mm"));
        time += long;
      }
      return categoryData;
    },

    // 获取实时时间
    getNowTime() {
      let that = this;
      let theNowTime = function () {
        that.nowTime = that.timeNumber();
      };
      this.setInterval = setInterval(theNowTime, 1000);
    },
    timeNumber() {
      let today = new Date();
      let time =
        this.twoDigits(today.getHours()) +
        ":" +
        this.twoDigits(today.getMinutes());
      return time;
    },
    twoDigits(val) {
      if (val < 10) return "0" + val;
      return val;
    },

    async loadFullDataSets() {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200 && data) {
        for (const index in data) {
          this.loadSepecificDataSet(data[index]);
        }
      }
    },

    async loadSepecificDataSet(data) {
      switch (data["name"]) {
        case "土壤检测":
          this.getChemicalList("turang", data["id"]);
          break;
        case "气象采集":
          this.getChemicalList("qixiang", data["id"]);
          break;
        case "视频监控":
          this.videoId = data["id"];
          this.getVideoList();
          break;
        default:
          break;
      }
    },

    async getVideoList(pageNum = 1) {
      const { code, results, count, page_num, page_size } =
        await getEquipmentListApi({
          manor_id: this.farmID,
          channel_id: this.videoId,
          page_num: pageNum,
        });
      if (code === 0 && Array.isArray(results)) {
        this.chemicalList["jiankong"] = results.slice(0, 6);

        this.videoCount = count;
        this.videoPageNum = page_num;
        this.videoPageSize = page_size;
        this.$forceUpdate();
      }
    },
    async getChemicalList(type, channel_id) {
      const { code, results } = await getEquipmentListApi({
        // manor_id: this.farmID, //早晚 改
        manor_id: 7,
        channel_id,
      });

      if (code === 0 && Array.isArray(results)) {
        let chemicalBasics = results[0];
        const mergeObj = (a, b) => {
          return { ...a, ...b, last_data: b.last_data };
        };

        if (chemicalBasics) {
          this.chemicalList[type] = R.zipWith(
            mergeObj,
            await this.getChemicalChildList(chemicalBasics.id),
            await this.getChemicalInfo(chemicalBasics.id)
          );
        }
        if (type === "jiankong") {
          this.chemicalList["jiankong"] = results;
        }
        this.chemicalList[type].pop();
      }
    },

    async getChemicalInfo(table_id) {
      const { code, results } = await getEquipmentInfoApi({
        manor_id: this.farmID,
        table_id,
        act: "status",
      });
      if (code === 0 && results) {
        return results.data;
      }
    },

    async getChemicalChildList(table_id) {
      const { code, results } = await getEquipmentChildListApi({
        manor_id: this.farmID,
        table_id,
      });
      if (code === 0 && results) {
        results.forEach((t) => {
          if (t.alias == "airT") {
            this.getEquipmentChildListInfoApi(table_id, t.id);
          }
        });

        return results;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  min-height: 100vh;
  background: #051218;
  .header {
    width: 100%;
    height: 72px;
    padding: 0 35px;

    background: url(../assets/image/bigWeb/head/top.png) center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .now {
      display: flex;
      align-items: center;
      .date {
        font-size: 21px;
        font-weight: 500;
        color: #acd1ff;
        margin-right: 47px;
      }
      .time {
        width: 100px;
        font-size: 36px;
        font-family: DS-Digital;
        font-weight: bold;
        color: #acd1ff;
        margin-right: 38px;
      }
      .weather {
        height: 23px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        border-left: 1px solid #3e90e5;
        img {
          width: 28px;
          height: 28px;
          margin-right: 15px;
        }
        span {
          font-size: 21px;
          font-weight: 500;
          color: #acd1ff;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .logo-img {
        width: 56px;
        height: 26px;
        margin-right: 20px;
      }
      .logo-txt {
        font-size: 32px;
        font-weight: bold;
        color: #fff;
      }
    }

    .title {
      font-size: 21px;
      font-weight: 500;
      color: #acd1ff;
      display: flex;
      align-items: center;
      .other-txt {
        width: 27px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
  .main {
    // padding: 0 21px;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .equipment,
    .traceability,
    .monitor,
    .stoving,
    .personnel,
    .real-time-data {
      width: 425px;
      width: 424px;
      height: 303px;
      background: url("../assets/image/bigWeb/equipment/backdrop.png") center
        center no-repeat;
      background-size: cover;
      position: relative;
      padding: 54px 8px 13px;
      margin-bottom: 25px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: transparent;
        background: linear-gradient(0deg, #03b8de 0%, #0085ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: absolute;
        top: 9px;
        left: 51px;
        &.now-data {
          top: 12px;
          left: 61px;
        }
      }
      .lists {
        width: 100%;

        .items {
          width: 100%;
          height: 51px;
          background: #0e3248;
          padding: 0 8px;
          margin: 0 0 11px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-of-type {
            margin: 0;
          }
          .items-title {
            font-size: 14px;
            color: #fff;
            position: relative;
            padding-left: 13px;
            &::before {
              content: "";
              width: 2px;
              height: 12px;
              background: #3e90e5;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .item-switch {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .switches {
              display: flex;
              align-items: center;

              .name {
                width: 60px;
                font-size: 12px;
                color: #fff;
                display: inline-block;
                text-align: right;
              }
              .switch-img {
                width: 18px;
                height: 18px;
                vertical-align: sub;
                margin: 0 8px 0 18px;
                cursor: pointer;
              }

              .status {
                font-size: 12px;
                color: #e6855b;
                &.active {
                  color: #3e90e5;
                }
              }
            }
          }
        }
      }
    }
    .equipment {
      .rice-main {
        width: 100%;
        height: 100%;
        position: relative;
        background: url("../assets/image/bigWeb/equipment/bg.png") center center
          no-repeat;
        background-size: contain;
        .rice-all {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .icon {
            width: 41px;
            height: 35px;
            margin-bottom: 6%;
          }
          .number {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 3px;

            span {
              font-size: 20px;
              font-weight: bold;
              color: #3e90e5;
              &:last-of-type {
                font-size: 12px;
                font-weight: 400;
                color: #fff;
              }
            }
          }
          .unit {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
          }
        }
        .rice-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 40px;
          left: 17px;
          &:nth-of-type(2) {
            top: 40px;
            right: 11px;
            left: inherit;
            bottom: inherit;
          }
          &:nth-of-type(3) {
            left: 20px;
            bottom: 37px;
            top: inherit;
            right: inherit;
          }
          &:nth-of-type(4) {
            top: inherit;
            left: inherit;
            right: 18px;
            bottom: 37px;
          }
          .number {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3px;

            span {
              font-size: 14px;
              font-weight: bold;
              color: #3e90e5;
              &:last-of-type {
                font-size: 12px;
                font-weight: 400;
                color: #fff;
              }
            }
          }
          .unit {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
          }
        }
      }
    }
    .traceability {
      .traceability-lists {
        height: 100%;
        width: 100%;
        .distribute-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
    .monitor {
      width: 426px;
      height: 548px;
      background: url("../assets/image/bigWeb/monitor/backdrop.png") center
        center no-repeat;
      background-size: cover;
      .monitor-lists {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        // overflow-y: auto;
        // &::-webkit-scrollbar {
        //   width: 7px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   background-color: #3e90e5;
        // }
        .monitor-items {
          width: 192px;
          height: 136px;
          margin: 0 12px 26px 0;
          overflow: hidden;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .stoving {
      width: 426px;
      height: 373px;
      padding-top: 70px;
      padding-left: 20px;
      padding-right: 20px;
      background: url("../assets/image/bigWeb/stoving/dry_bg.png") center center
        no-repeat;
      background-size: cover;

      .stoving-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .stoving-item {
          width: 184px;
          height: 79px;
          background: #0a2431;
          border: 1px solid;
          margin: 0 18px 15px 0;
          padding: 16px 0 0 27px;
          border-image: linear-gradient(
              90deg,
              rgba(74, 102, 131, 0.98),
              rgba(22, 60, 99, 0.98),
              rgba(54, 107, 161, 0.98)
            )
            1 1;
          display: flex;
          align-items: center;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
          .dry-img {
            width: 57px;
            height: 48px;
            margin-right: 12px;
          }
          .dry-info {
            width: calc(100% - 69px);
            height: 48px;
            display: flex;
            flex-direction: column;
            .info-title {
              font-size: 12px;
              font-weight: 400;
              color: #bfcadd;
            }
            .info-cont {
              font-size: 18px;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }
    .other {
      width: 984px;
      height: 631px;
      position: relative;
      margin-bottom: 25px;
      .nook {
        width: 15px;
        height: 12px;
        position: absolute;
        z-index: 2000;
      }

      .nook-top-left {
        border-top: 2px solid #3e90e5;
        border-left: 2px solid #3e90e5;
        top: 0px;
        left: 0px;
      }

      .nook-bottom-left {
        border-bottom: 2px solid #3e90e5;
        border-left: 2px solid #3e90e5;
        bottom: 0px;
        left: 0px;
      }

      .nook-top-right {
        border-top: 2px solid #3e90e5;
        border-right: 2px solid #3e90e5;
        top: 0px;
        right: 0px;
      }

      .nook-bottom-right {
        border-bottom: 2px solid #3e90e5;
        border-right: 2px solid #3e90e5;
        bottom: 0px;
        right: 0px;
      }
    }
    .real-time-data {
      width: 985px;
      background: url("../assets/image/bigWeb/realData/backdrop.png") center
        center no-repeat;
      background-size: cover;

      padding: 60px 19px 13px;
      .data-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .content-items {
          flex: 2;
          .contents-title {
            font-size: 14px;
            color: #fff;
            font-weight: bold;
            position: relative;
            padding-left: 13px;
            margin: 0 28px 13px 0;
            &::before {
              content: "";
              width: 2px;
              height: 12px;
              background: #3e90e5;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .contents-main {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .contents-main-items {
              width: 226px;
              height: 45px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              &:nth-of-type(2n) {
                margin: 0;
              }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
          &.weather-cont {
            display: flex;
            flex-wrap: wrap;
            .contents-title {
              width: 226px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #fff;
              font-weight: bold;
              position: relative;
              margin: 0 9px 7px 0;
              padding: 0 13px;

              &::before {
                content: "";
                width: 2px;
                height: 12px;
                background: #3e90e5;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .contents-main-items.weather-cont-items {
              width: 226px;
              height: 40px;
              background: #0e3248;
              margin: 0 9px 7px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 13px;
              // &:nth-of-type(2n) {
              //   margin: 0;
              // }
              div.l {
                display: flex;
                align-items: center;
                img {
                  width: 32px;
                  height: 32px;
                  margin-right: 10px;
                }
                font-size: 12px;
              }
              div.r {
                font-size: 16px;
                font-weight: 500;
                &.active {
                  color: #f2423e;
                }
              }
            }
          }
        }
      }
    }

    .personnel {
      width: 426px;
      height: 305px;
      // padding: 40px 0 0 0;
      .chart-personnel {
        width: 100%;
        height: 100%;

        .v-chart {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
